import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AboutUs, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu } from './container';
import { Navbar } from './components';
import BookingForm from './components/BookingTable/BookingForm';
import './App.css';

const App = () => (
  <Router>
    <div>
      {/* Navbar is present on all routes */}
      <Navbar />
      <Routes>
        {/* Home route */}
        <Route
          path="/"
          element={
            <>
              {/* Each section is a separate component */}
              <Header />
              <AboutUs />
              <SpecialMenu />
              <Intro />
              <Gallery />
              <FindUs />
              <Footer />
            </>
          }
        />
        {/* Book Table route */}
        <Route path="/book-table" element={<BookingForm />} />
      </Routes>
    </div>
  </Router>
);

export default App;
