import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => {
  const handleVisitUsClick = () => {
    // Replace the address below with the actual address of your restaurant
    const restaurantAddress = '7799 Leesburg Pike, Falls Church, VA 22043';
    
    // Construct the Google Maps URL with the address
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(restaurantAddress)}`;

    // Open the URL in a new tab
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div className="app__bg app__wrapper section__padding" id="contact">
      <div className="app__wrapper_info">
        <SubHeading title="Address" />
        <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
        <div className="app__wrapper-content">
          <p className="p__opensans">7799 Leesburg Pike, Falls Church, VA 22043</p>
          <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
          <p className="p__opensans">Mon - Sat: 10:00 am - 9:00 pm</p>
          <p className="p__opensans">Sun: 10:00 am - 08:00 pm</p>
        </div>
        <button type="button" className="custom__button" style={{ marginTop: '2rem' }} onClick={handleVisitUsClick}>
          Visit Us
        </button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.findus} alt="finus_img" />
      </div>
    </div>
  );
};

export default FindUs;
