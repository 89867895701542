import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import MenuImage from '../../assets/LunchMenu.png';

import './Header.css';

const Header = () => {
  const openMenu = () => {
    // Open the imported image file in a new tab
    window.open(MenuImage, '_blank');
  };

  return (
    <div className="app__header app__wrapper section__padding app__bg" id="home">
      <div className="app__wrapper_info">
        <SubHeading title="Chase the new flavour" />
        <h1 className="app__header-h1">Atrium Korean Tofu and BBQ</h1>
        <p className="p__opensans" style={{ margin: '2rem 0' }}>Experience the authentic Korean flavours, enjoy our vast list of items that are available throughout the day. Elevate your corporate events with Atrium, located in the heart of corporate offices. Atrium is the perfect venue for your lunch and dinner gatherings.</p>
        <button type="button" className="custom__button" onClick={openMenu}>
          Explore Menu
        </button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.chinesecabbage} alt="header_img" />
      </div>
    </div>
  );
};

export default Header;
