import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.jpg';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import atrium_name from '../assets/atrium_name.png';
import atrium from '../assets/atrium.png';
import bibimbap from '../assets/bibimbap.png';
import a from '../assets/a.png';
import pancake from '../assets/pancake.png';
import short_ribs from '../assets/short_ribs.png';
import friedrice from '../assets/friedrice.png';
import  bulgogi from '../assets/bulgogi.png';
import  tofu_soup from '../assets/tofu_soup.png';
import  collage1 from '../assets/collage1.png';
import soup_tofu from '../assets/soup_tofu.png';
import chinesecabbage from '../assets/chinesecabbage.png';
import  parking from '../assets/parking.png';
import  hallway from '../assets/Hallway.png';
import  main_entrance from '../assets/main_entrance.png';
import cafe from '../assets/cafe.png';
import BreakfastMenu from '../assets/BreakfastMenu.png';
import korea_wall from '../assets/korea_wall.jpg';
import tv from '../assets/tv.jpg';
import cornerview_2 from '../assets/cornerview_2.jpeg';
import amb from '../assets/amb.jpg';
import coffee from '../assets/coffee.jpg';
import gallery_hotstone from '../assets/gallery_hotstone.jpg';
import gallery_dump from '../assets/gallery_dump.jpg';

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  friedrice,
  short_ribs,
  pancake,
  bulgogi,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  atrium_name,
  atrium,
  bibimbap,
  a,
  tofu_soup,
  collage1,
  soup_tofu,
  chinesecabbage,
  cafe,
  main_entrance,
  hallway,
  parking,
  BreakfastMenu,
  korea_wall,
  cornerview_2,
  coffee,
  amb,
  tv,
  gallery_dump,
  gallery_hotstone,
};
