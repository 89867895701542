// BookingForm.jsx
import React, { useState } from 'react';
import './BookingForm.css'; // Import the CSS file

const BookingForm = () => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(true); // Set to true for maintenance

  const handleBooking = (e) => {
    e.preventDefault();

    if (isUnderMaintenance) {
      console.log('Booking feature is under maintenance.');
      return;
    }

    // Perform booking logic here
    console.log('Booking details:', { name, date, time });
  };

  return (
    <div className="booking-form-container">
      <h2>Book a Table</h2>
      {isUnderMaintenance ? (
        <p style={{ color: 'red' }}>Booking feature is currently under maintenance, Please check back later. To book a table please call 571-378-1314</p>
      ) : (
        <form onSubmit={handleBooking}>
          <label>
            Name:
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </label>
          <br />
          <label>
            Date:
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </label>
          <br />
          <label>
            Time:
            <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
          </label>
          <br />
          <button type="submit">Book Table</button>
        </form>
      )}
    </div>
  );
};

export default BookingForm;
