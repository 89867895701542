import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">


    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Discover the essence of Korea at Atrium. We're your gateway to authentic Korean flavors, where every dish is a taste of tradition and a journey through culture. Join us and savor the heart of Korea..</p>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">We have been a labor of love and a tribute to the rich heritage of Korean cuisine. Established in 2022, we've proudly served the NorthernVirginia community for over a year. Our journey is one of passion, family recipes, and a commitment to sharing the soulful flavors of Korea.</p>
      </div>
    </div>
  </div>
);

export default AboutUs;