import images from './images';

const wines = [
  {
    title: 'Hot stone bowl bibimbap',
    price: '$18.95',
    tags: 'Choice of beef | pork | chicken | seafood | tofu | kimchi | vegetables',
  },
  {
    title: 'Beef short Ribs',
    price: '$24.95',
    tags: 'LA Style',
  },
  {
    title: 'Japchae',
    price: '$19.95',
    tags: 'Choice of Beef | chicken |  tofu | vegetables',
  },
  {
    title: 'Spicy Stir fried Squid',
    price: '$21.95',
    tags: '',
  },
  {
    title: "Bulgogi",
    price: '$19.95',
    tags: 'Beef + $1 | Pork | chicken',
  },
  {
    title: 'Regular bowl bibimbap',
    price: '$18.95',
    tags: 'Choice of beef | pork | chicken | seafood | tofu | kimchi | vegetables',
  },
];

const cocktails = [
  {
    title: "Soft Tofu Soup",
    price: '$12.95',
    tags: 'Noodles + $3 | Ramen + $2 | Your choice of toppings',
  },
  {
    title: 'Crispy sea food pancake',
    price: '$15.95',
    tags: 'A massive and mouth-watering pancake that you must experience.',
  },
  {
    title: 'Stir fried Udon',
    price: '$19.95',
    tags: 'Choice of Beef | chicken |  seafood | vegetables',
  },
  {
    title: 'Fried Rice',
    price: '$13.95',
    tags: 'Choice of shrimp | Beef | Chicken | Vegetables',
  },
  {
    title: 'Fried or boiled mando (Dumplings)',
    price: '$10.95',
    tags: '',
  },
  {
    title: 'Cold Noodle (Mixed)',
    price: '$15.95',
    tags: '',
  },
  {
    title: 'Cold Noodle (Water)',
    price: '$14.95',
    tags: '',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards };
