import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleBookTableClick = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <nav className="app__navbar app__bg">
      <div className="app__navbar-logo">
        <img src={images.atrium_name} alt="app__logo" />
      </div>
      {/* Navigation Links */}
      <ul className={`app__navbar-links ${toggleMenu ? 'hidden' : ''}`}>
        <li className="p__opensans"><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
        <li className="p__opensans"><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
        <li className="p__opensans"><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li>
        <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
      </ul>
      {/* Book a Table Link */}
      <div className="app__navbar-login">
        <Link to="/book-table" className="p__opensans" onClick={() => setToggleMenu(false)}>
          Book a Table
        </Link>
      </div>
      {/* Hamburger Menu for Smaller Screens */}
      <div className="app__navbar-mobile" onClick={() => setToggleMenu(!toggleMenu)}>
        <GiHamburgerMenu color="#fff" fontSize={27} />
      </div>
      {/* Small Screen Overlay */}
      {toggleMenu && (
        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
          <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
          <ul className="app__navbar-smallscreen_links">
            <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
            <li><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
            <li><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li>
            <li><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

